import React, { useState, useEffect } from "react";
import { getSchool } from '../../services/scpApi';
import {useSelector} from "react-redux";


const Dashboard = () => {

    const [school, setSchool] = useState([]);
    const user = useSelector(state => state.auth.authUser);

    // user object
    useEffect(() => {
        console.log('auth user', user);

        // get school for the user
        if (user.school_id) {
            (async () => {
                try {
                    const data = await getSchool(user.school_id);
                    setSchool(data.school);
                } catch (error) {
                    console.error('Error fetching school:', error);
                }
            })();
        }
    }, [user]);

    return (
        <div className="dashboard">
            <h1>School Consultancy Programme - Student Tracking Portal</h1>

            <div className="separator"></div>

            {school && school.name ? (
                <h3>Welcome to the {school.name} student tracking portal.</h3>
            ) : (
                <>
                {user.user_role === 'super_admin' &&
                    <h3>Welcome to the School Consultancy Programme admin portal</h3>
                }
                </>
            )}

            <p>Please use the relevant links within the side bar to view, update and track your student data.</p>

        </div>
    );
}

export default Dashboard;