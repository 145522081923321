import React from "react";
import LoginLayout from "../../layouts/LoginLayout";
import LoginForm from "../../features/loginForm/LoginForm";

const LoginPage = () => {
    return (
        <LoginLayout>
            
            <h1>The School Consultancy Programme</h1>

            <div className="separator"></div>

            <LoginForm />

        </LoginLayout>
    );
}

export default LoginPage;