import React, { useState, useEffect } from 'react';
import { getUsers } from '../../services/scpApi';
import dotsVertical from '../../assets/dots-vertical.svg';


const CrudUserList = ({setSelectedSingleItemId, setView}) => {

    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    // fetch users
    useEffect(() => {
        (async () => {
            try {
                // fetch user data
                const data = await getUsers();

                // collect the relevant data from the response
                const users = data.map(user => {
                    return {
                        id: user.id,
                        email: user.email,
                        name: user.name,
                        userRole: user.user_role
                    };
                });

                // set the users data
                setUsers(users);
                setMessage(users.length > 0 ? '' : 'No users found');
            } catch (error) {
                console.error('Error fetching users:', error);
                setMessage('Error fetching users');
                setUsers([]);
            }
        })();

    }, [currentPage]);

    const handleEditClick = (user) => {
        setSelectedSingleItemId(user.id);
        setView('single');
    };

    const columns = [
        { columnLabel: 'Name', dataKey: 'name' },
        { columnLabel: 'Email', dataKey: 'email' },
        { columnLabel: 'Role', dataKey: 'userRole' }
    ];

    return (
        <div className="">
            <div className="separator"></div>
            <div className='crud-list'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.columnLabel}>{column.columnLabel}</th>
                            ))}
                            <th>View / Edit</th>
                        </tr>
                        <tr className="separator-row"></tr>
                    </thead>

                    <tbody>
                        {message ? (
                            <tr>
                                <td colSpan={columns.length + 1}>{message}</td>
                            </tr>
                        ) : (
                            users.length > 0 ? (
                                users.map(user => (
                                    <tr key={user.id}>
                                        {columns.map(column => (
                                            <td key={column.dataKey}>{user[column.dataKey]}</td>
                                        ))}
                                        <td className='edit-dots'>
                                            <button
                                                onClick={() => handleEditClick(user)}
                                                className='edit-dots'>
                                                <img src={dotsVertical} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length + 1}>No users found</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CrudUserList;