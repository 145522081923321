import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit';
import client from '../services/scpApi';
import { getAuthToken, getAuthUser, setAuthToken, setAuthUser, removeAuthToken, removeAuthUser } from '../services/storage';


export const login = createAsyncThunk('auth/login', async ({email, password}) => {

    let response;

    try {
        response = await client.post('/login', {email, password});
    } catch (error) {
        response = error.response;
    }

    return response;
})

export const logout = createAction('auth/logout');

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authUser: getAuthUser() ? JSON.parse(getAuthUser()) : '',
        accessToken: getAuthToken() ? getAuthToken() : '',
        authLoading: false,
        authError: ''
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(login.pending, (state, action) => {
                state.authLoading = true;
                state.authError = '';
            })
            .addCase(login.fulfilled, (state, action) => {
                state.authLoading = false;
                state.authError = action.payload.data.message ? action.payload.data.message : '';

                if (action.payload.data.access_token && action.payload.data.user) {
                    state.accessToken = action.payload.data.access_token;
                    state.authUser = action.payload.data.user;

                    // store in local storage
                    setAuthToken(action.payload.data.access_token);
                    setAuthUser(JSON.stringify(action.payload.data.user));

                    // redirect to portal
                    window.location.href= '/portal';
                }
            })
            .addCase(logout, (state, action) => {
                // Clear auth state
                state.authUser = '';
                state.accessToken = '';

                // Remove from local storage
                removeAuthToken();
                removeAuthUser();

                // Redirect to login page
                window.location.href = '/login';
            });
    }
})

export default authSlice;