import React, {useEffect, useState} from "react";
import { calcTotalLevel } from "../../includes/utilities";


const LevelDisplay = ({levels, startsAt, averageScore}) => {

    const [levelTemplate] = useState([1,2,3,4,5,6]);
    const [displayLevels, setDisplayLevels] = useState([]);
    const [totalClass, setTotalClass] = useState('');
    const [totalLevel, setTotalLevel] = useState('');

    useEffect(() => {

        if (typeof levels == 'undefined') {
            return;
        }

        let newDisplayLevels = levelTemplate.map((level, i) => {
            return levels[i] || '';
        });

        setDisplayLevels(newDisplayLevels);

        const newTotalLevel = calcTotalLevel(levels, startsAt);

        if (newTotalLevel == 0) {
            setTotalClass('');
        } else if (newTotalLevel < 6) {
            setTotalClass('half-complete');
        } else {
            setTotalClass('complete');
        }

        setTotalLevel(newTotalLevel);

    }, [levels])

    return (
        <div className="level-display-cont">
            {!averageScore &&
                <>
                    {displayLevels.length > 0 && displayLevels.map((figure, i) => (
                        <span className={`level ${i+1} ${figure == '1' ? 'complete' : figure == '0.5' ? 'half-complete' : '' } ${i + 1 < startsAt && 'disabled'}`}>{figure}</span>
                    ))}
                    <span className={`level total ${totalClass}`}>{totalLevel}</span>
                </>
            }

            {averageScore && 
                <span className={`level total average ${averageScore}`}>{averageScore}</span>
            }
        </div>
    )
}

export default LevelDisplay;