import React from "react";


const ProgressOutcomeStatements = ({bundlessWithIntervention, currentPeriod}) => {

    return (
        <div className="progress-outcome-statements-inner">
            <h3>Outcome statements</h3>
            <span>Outcomes for bundles completed in period: {currentPeriod.period_name} {currentPeriod.calendar_year}</span>

            {bundlessWithIntervention && bundlessWithIntervention.map((bundle) => (
                <div className="outcome">
                    <h5>{bundle.levelLabel} {bundle.label}</h5>
                    <p>{bundle.outcome}</p>
                </div>
            ))}
        </div>
    )
}

export default ProgressOutcomeStatements;