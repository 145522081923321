import React, { useState, useEffect } from 'react';
import CrudTutorListFilters from './CrudTutorListFilters';
import { getTutors } from '../../services/scpApi';
import dotsVertical from '../../assets/dots-vertical.svg';

const CrudTutorList = ({setSelectedSingleItemId, setView}) => {

    const [filters, setFilters] = useState({
        school: null
    });
    const [tutors, setTutors] = useState([]);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    // fetch tutors
    useEffect(() => {
        (async () => {
            if (!filters.school) {
                setMessage('Please select a school');
                setTutors([]);
                return;
            }

            try {
                // fetch tutors data
                const data = await getTutors({
                    schoolId: filters.school ? filters.school.id : null
                });

                // collect the relevant data from the response
                const tutors = data.map(tutor => {
                    return {
                        id: tutor.id,
                        name: tutor.name
                    };
                });

                // set the students data
                setTutors(tutors);
                setMessage(tutors.length > 0 ? '' : 'No tutors found');
            } catch (error) {
                console.error('Error fetching tutors:', error);
                setMessage('Error fetching tutors');
                setTutors([]);
            }
        })();

    }, [filters.school, currentPage]);

    const handleEditClick = (tutor) => {
        setSelectedSingleItemId(tutor.id);
        setView('single');
    };

    const clearFilters = () => {
        setFilters({
            school: null
        });
    }

    const columns = [
        { columnLabel: 'Name', dataKey: 'name' }
    ];

    return (
        <div className="">
            <a onClick={() => clearFilters()} className="clear-filters-btn">Clear Filters</a>
            <CrudTutorListFilters filters={filters} setFilters={setFilters} />
            <div className="separator"></div>
            <div className='crud-list'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.columnLabel}>{column.columnLabel}</th>
                            ))}
                            <th>View / Edit</th>
                        </tr>
                        <tr className="separator-row"></tr>
                    </thead>

                    <tbody>
                        {message ? (
                            <tr>
                                <td colSpan={columns.length + 1}>{message}</td>
                            </tr>
                        ) : (
                            tutors.length > 0 ? (
                                tutors.map(tutor => (
                                    <tr key={tutor.id}>
                                        {columns.map(column => (
                                            <td key={column.dataKey}>{tutor[column.dataKey]}</td>
                                        ))}
                                        <td className='edit-dots'>
                                            <button
                                                onClick={() => handleEditClick(tutor)}
                                                className='edit-dots'>
                                                <img src={dotsVertical} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length + 1}>No tutors found</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default CrudTutorList;