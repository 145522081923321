import React, { useState, useEffect } from "react";
import CrudStudentsList from '../../features/crudStudent/CrudStudentsList';
import CrudStudentSingle from '../../features/crudStudent/CrudStudentSingle';
import { getStudent } from '../../services/scpApi';

const ManageStudents = () => {

    const [view, setView] = useState("list");
    const [selectedSingleItemId, setSelectedSingleItemId] = useState(null);
    const [selectedStudent, setStudent] = useState(null);
    const [loading, setLoading] = useState(null);

    const handleAddNew = () => {
        setView("single");
        setSelectedSingleItemId(null);
        setStudent(null);
    };

    const handleViewChange = ({isBackToList = false}) => {
        console.log(isBackToList);
        if (isBackToList) {
            setView("list");
            return;
        }

        setView(view === "list" ? "single" : "list");
    };

    const refreshSelectedStudent = () => {
        if (!selectedSingleItemId) {
            return;
        }

        (async () => {
            try {
                const student = await getStudent(selectedSingleItemId); 
                setStudent(student);
            } catch (error) {
                console.error('Error fetching student:', error);
            }
        })();
    }

    useEffect(() => {
        (async () => {
            if (!selectedSingleItemId) {
                return;
            }
            try {
                // fetch student data
                const student = await getStudent(selectedSingleItemId);
                setStudent(student);
            } catch (error) {
                console.error('Error fetching student:', error);
                setLoading('error');
            }
        })();
    }, [selectedSingleItemId]);

    return (
        <div className="manage-students">
            <h1>Manage Students</h1>

            {view === "list" ? (
                <>
                    <button onClick={handleAddNew} className="add-new">Add New Student</button>
                    <CrudStudentsList setSelectedSingleItemId={setSelectedSingleItemId} setView={handleViewChange} />
                </>
            ) : (
                <CrudStudentSingle 
                    loading={loading}
                    setLoading={setLoading} 
                    student={selectedStudent}
                    setStudent={setStudent} 
                    setView={handleViewChange} 
                    refreshSelectedStudent={refreshSelectedStudent} 
                />
            )}
        </div>
    );
};

export default ManageStudents;