import React, {useState, useEffect} from "react";
import LevelDisplay from "../levelDisplay/LevelDisplay";
import {calcAssessmentAverageScore, calcNumbersAreaAverageScore} from "../../includes/utilities";


const ProgressTable = ({selectedPeriods}) => {

    const [assessments, setAssessments] = useState([]);
    const [averages, setAverages] = useState([]);
    const [numbersAverages, setNumbersAverages] = useState([]);

    useEffect(() => {
        if (!selectedPeriods || selectedPeriods.length < 1) {
            return;
        }

        const newAssessments = [];
        const newAverages = [];
        const newNumbersAverages = [];

        // collected assessments and calc averages for this period
        selectedPeriods.forEach((period) => {
            if (period.assessment) {
                newAssessments.push(period.assessment)

                const averageScore = calcAssessmentAverageScore(period.assessment);
                newAverages.push(averageScore);

                const numbersAverage = calcNumbersAreaAverageScore(period.assessment);
                newNumbersAverages.push(numbersAverage);
            }
        })

        setAssessments(newAssessments);
        setAverages(newAverages);
        setNumbersAverages(newNumbersAverages);
    }, [selectedPeriods])

    return (
        <div className="progress-table">
            <table>
                <thead>
                    <tr>
                        <th>Area</th>
                        <th>Topic</th>
                        <th>Period</th>
                        <th>Level</th>
                    </tr>
                </thead>
                <tbody>
                    {assessments && assessments.length > 0 && Object.keys(assessments[0]).map((area) => 
                        <>
                        {assessments[0][area].topics.map((topic, topicIndex) =>
                            <tr>
                                <td>{assessments[0][area].label}</td>
                                <td>{topic.label}</td>
                                <td>
                                    <table>
                                        {selectedPeriods.map((selectedPeriod) => 
                                            <tr>
                                                <td>{`${selectedPeriod.period_name} ${selectedPeriod.calendar_year}`}</td>
                                            </tr>
                                        )}
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        {assessments.map((assessment) => 
                                            <tr>
                                                <td>
                                                    <LevelDisplay
                                                        levels={assessment[area]['topics'][topicIndex]['level']}
                                                        startsAt={topic['starts-at'] || 1}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </table>
                                </td>
                                <td>
                                    {topic.slug == 'ratio-and-proportion' && 
                                        <table style={{position: 'relative'}}>
                                            <span class="numbers-average-note">Number Areas Average</span>
                                            {numbersAverages.map((average) =>
                                                <tr>
                                                    <td>
                                                        <LevelDisplay
                                                            averageScore={average}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </table>
                                    }
                                </td>
                            </tr>
                        )}
                        </>
                    )}
                    <tr>
                        <td>Averages</td>
                        <td></td>
                        <td>
                            <table>
                                {selectedPeriods.map((selectedPeriod) => 
                                    <tr>
                                        <td>{`${selectedPeriod.period_name} ${selectedPeriod.calendar_year}`}</td>
                                    </tr>
                                )}
                            </table>
                        </td>
                        <td></td>
                        <td>
                            <table className="averages-table" style={{position: 'relative'}}>
                                <span class="numbers-average-note">Total Average</span>
                                {averages.map((average) => 
                                    <tr>
                                        <td>
                                            <LevelDisplay
                                                averageScore={average}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ProgressTable;