import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Collapsible from "../collapsible/Collapsible";

const Sidebar = () => {

    const user = useSelector(state => state.auth.authUser);

    return (
        <div className="sidebar">
            <Collapsible title="Tracking" open={true}>
                <Link to="/portal/update-student-progress">Update student progress</Link>
            </Collapsible>

            <Collapsible title="Reporting" open={true}>
                <Link to="/portal/student-progress">Student progress</Link>
                {user.user_role !== 'tutor' && (
                    <Link to="/portal/group-reports">Group reports</Link>
                )}
            </Collapsible>

            {user.user_role === 'tutor' && (
                <Collapsible title="Admin" open={true}>
                    <Link to="/portal/manage-students">Manage students</Link>
                </Collapsible>
            )}

            {user.user_role === 'slt_member' && (
                <Collapsible title="Admin" open={true}>
                    <Link to="/portal/manage-students">Manage students</Link>
                    <Link to="/portal/manage-tutors">Manage tutor group leads</Link>
                    <Link to="/portal/manage-tutor-groups">Manage tutor groups</Link>
                </Collapsible>
            )}

            {user.user_role === 'super_admin' && (
                <Collapsible title="Admin" open={true}>
                    <Link to="/portal/manage-students">Manage students</Link>
                    <Link to="/portal/manage-tutors">Manage tutor group leads</Link>
                    <Link to="/portal/manage-tutor-groups">Manage tutor groups</Link>
                    <Link to="/portal/manage-schools">Manage schools</Link>
                    <Link to="/portal/manage-users">Manage users</Link>
                </Collapsible>
            )}
        </div>
    );
}

export default Sidebar;