import React, { useState, useEffect } from 'react';
import { createSchool, updateSchool } from '../../services/scpApi'; 
import Repeater from '../repeater/Repeater';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveButton from '../saveButton/SaveButton';

const CrudSchoolSingle = ({ school, setSchool, loading, setLoading, setView, refreshSelectedSchool }) => {

    const [formData, setFormData] = useState({
        name: '',
        address: '',
        website: '',
        contacts: [{ title: '', name: '', job_title: '', email: '', phone: '', address: '' }],
        reporting_periods: [{ periodStartDate: null, periodEndDate: null, name: '' }]
    });
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (!school) return;

        const newFormData = {
            name: school.name || '',
            address: school.address || '',
            website: school.website || '',
            contacts: school.contacts || [{ title: '', name: '', job_title: '', email: '', phone: '', address: '' }],
            reporting_periods: school.periods?.map(period => ({
                ...period,
                periodStartDate: new Date(period.start_date_month + '/' + period.start_date_day + '/' + new Date().getFullYear()),
                periodEndDate: new Date(period.end_date_month + '/' + period.end_date_day + '/' + new Date().getFullYear())
            })) || [{ periodStartDate: null, periodEndDate: null, name: '' }]
        };

        setFormData(newFormData);
    }, [school]);

    // Handle changes to contact fields
    const handleTextChange = (e, index) => {
        const { name, value } = e.target;
        setFormData(prevState => {
            const updatedContacts = [...prevState.contacts];
            updatedContacts[index] = { ...updatedContacts[index], [name]: value };
            return { ...prevState, contacts: updatedContacts };
        });
    };

    // Handle changes to reporting period fields
    const handleReportingPeriodChange = (e, index) => {
        const { name, value } = e.target;
        setFormData(prevState => {
            const updatedPeriods = [...prevState.reporting_periods];
            updatedPeriods[index] = { ...updatedPeriods[index], [name]: value };
            return { ...prevState, reporting_periods: updatedPeriods };
        });
    };

    // Handle date changes in reporting periods
    const handleDateChange = (date, name, index) => {
        setFormData(prevState => {
            const updatedPeriods = [...prevState.reporting_periods];
            updatedPeriods[index] = { ...updatedPeriods[index], [name]: date };
            return { ...prevState, reporting_periods: updatedPeriods };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationErrors([]);
        setLoading('loading');

        try {
            if (school) {
                await updateSchool({
                    schoolId: school.id,
                    name: formData.name,
                    address: formData.address,
                    website: formData.website,
                    contacts: formData.contacts,
                    reporting_periods: formData.reporting_periods
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    setView('list');
                }, 3000);

            } else {
                const school = await createSchool({
                    name: formData.name,
                    address: formData.address,
                    website: formData.website,
                    contacts: formData.contacts,
                    reporting_periods: formData.reporting_periods
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    //refreshSelectedSchool();
                    setSchool(school);
                }, 3000);

            }

        } catch (error) {
            console.error('Error saving school:', error);
            setValidationErrors([error.message]);

            setLoading('error');
            setTimeout(() => {
                setLoading(null);
            }, 3000);
        }
    };

    const routeKey = 'manage-schools';
    const handleMenuBtnClick = () => setView({ isBackToList: true });

    // add event listener to sidebar buttons to change view
    useEffect(() => {
        const sidebarButtons = document.querySelectorAll('.sidebar a[href^="/portal/' + routeKey + '"]');
        sidebarButtons.forEach(button => {
            // if it doesn't already have an event listener
            if (!button.hasEventListener) {
                button.addEventListener('click', handleMenuBtnClick);
            }
        })

        // Cleanup to prevent memory leaks and double bindings
        return () => {
            sidebarButtons.forEach(button => {
                button.removeEventListener('click', handleMenuBtnClick);
            });
        };
    }, []);

    return (
        <div className="single-edit">
            <h2>{school ? 'Edit School' : 'Add New School'}</h2>
            <form onSubmit={handleSubmit}>
                <div className='single-inner-cont'>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        required
                    />

                    <label>Address:</label>
                    <textarea
                        name="address"
                        value={formData.address}
                        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    />

                    <label>Website:</label>
                    <input
                        type="text"
                        name="website"
                        value={formData.website}
                        onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                    />

                    <div className="separator"></div>

                    <label>Contacts</label>
                    <Repeater 
                        items={formData.contacts}
                        setItems={(contacts) => setFormData(prevState => ({ ...prevState, contacts }))}
                        headings={['Title', 'Name', 'Job Title', 'Email', 'Phone', 'Address']}
                        buttonContent={'Add Additional Contact'}
                        handleChange={handleTextChange}
                        formRow={(contact, index, handleChange) => (
                            <>
                                <td>
                                    <input
                                        type="text"
                                        name="title"
                                        value={contact.title}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="name"
                                        value={contact.name}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="job_title"
                                        value={contact.job_title}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="email"
                                        name="email"
                                        value={contact.email}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="phone"
                                        value={contact.phone}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="address"
                                        value={contact.address}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </td>
                            </>
                        )}
                    />

                    <div className="separator"></div>

                    <label>Reporting Periods</label>
                    <Repeater 
                        items={formData.reporting_periods}
                        setItems={(reporting_periods) => setFormData(prevState => ({ ...prevState, reporting_periods }))}
                        headings={['Period Name', 'Start Date', 'End Date']}
                        buttonContent={'Add Reporting Period'}
                        formRow={(reporting_period, index, handleChange) => (
                            <>
                                <td>
                                    <input
                                        type="text"
                                        name="name"
                                        value={reporting_period.name || ''}
                                        onChange={(e) => handleReportingPeriodChange(e, index, 'name')}
                                    />
                                </td>
                                <td>
                                    <DatePicker
                                        selected={reporting_period.periodStartDate || null}
                                        onChange={(date) => handleDateChange(date, 'periodStartDate', index)}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </td>
                                <td>
                                    <DatePicker
                                        selected={reporting_period.periodEndDate || null}
                                        onChange={(date) => handleDateChange(date, 'periodEndDate', index)}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </td>
                            </>
                        )}
                    />
                </div>

                <div className="flex-row-buttons-cont">
                    <button onClick={() => { setView('list') }} className="grey-btn">Close</button>
                    <SaveButton
                        save={handleSubmit}
                        loading={loading}
                        textCreate="Add School"
                        textCreateLoading="Saving..."
                        textCreateSuccess="Saved"
                        textCreateError="Error"
                        textUpdate="Update School"
                        textUpdateLoading="Updating..."
                        textUpdateSuccess="Updated"
                        textUpdateError="Error"
                        isUpdating={!!school} 
                    />
                </div>

                {validationErrors.length > 0 &&
                    <div className="error-messages">
                        {validationErrors.map((error, index) => (
                            <p key={index}>{error}</p>
                        ))}
                    </div>
                }
            </form>
        </div>
    );
}

export default CrudSchoolSingle;