// EditStudentModal.js
import React, { useState, useEffect } from 'react';
import { createTutor, updateTutor } from '../../services/scpApi'; // Import API methods
import CustomSelect from '../customSelect/CustomSelect';
import { getSchools } from '../../services/scpApi';
import SaveButton from '../saveButton/SaveButton';


const CrudTutorSingle = ({tutor, setTutor, setView, loading, setLoading, refreshSelectedTutor}) => {

    const [formData, setFormData] = useState({
        school: '',
        name: '',
        email: ''
    });
    const [schools, setSchools] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);

    // Prepare form data for editing existing tutor
    useEffect(() => {
        if (!tutor) {
            return;
        }

        // Set form data for updating tutor
        const newFormData = {
            school: tutor.school.id || '',
            name: tutor.name || '',
            email: tutor.user?.email || ''
        };

        setFormData(newFormData);
    }, [tutor]);

    // Get schools for select
    useEffect(() => {
        (async () => {
            try {
                const schoolsData = await getSchools();
                setSchools(schoolsData);
            } catch (error) {
                console.error('Error fetching schools:', error);
            }
        })();
    }, []);

    // Handle form data changes
    const handleChange = (e) => {
        // Unset validation errors
        setValidationErrors([]);

        // Handle form data change
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationErrors([]);

        // Save tutor
        try {
            setLoading('loading');
            if (tutor) {
                // Editing existing tutor
                await updateTutor({
                    id: tutor.id,
                    school: formData.school,
                    name: formData.name,
                    email: formData.email
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    refreshSelectedTutor();
                }, 3000);

            } else {
                // Creating new tutor 
                const tutor = await createTutor({
                    school: formData.school,
                    name: formData.name,
                    email: formData.email
                });

                setLoading('success');
                setTimeout(() => {
                  setLoading(null);
                  //refreshSelectedTutor();
                  setTutor(tutor);
                }, 3000);

            }
        } catch (error) {
            // Server-side validation
            const errors = [];

            if (error.response?.data?.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    errors.push(error.response.data.errors[key]);
                });
            } else if (error.response?.data?.message) {
                errors.push(error.response.data.message);
            }
            setValidationErrors(errors);
            console.error('Error saving tutor:', error);

            setLoading('error');
            setTimeout(() => {
                setLoading(null);
            }, 3000);
        }
    };

    const routeKey = 'manage-tutors';
    const handleMenuBtnClick = () => setView({ isBackToList: true });

    // add event listener to sidebar buttons to change view
    useEffect(() => {
        const sidebarButtons = document.querySelectorAll('.sidebar a[href^="/portal/' + routeKey + '"]');
        sidebarButtons.forEach(button => {
            // if it doesn't already have an event listener
            if (!button.hasEventListener) {
                button.addEventListener('click', handleMenuBtnClick);
            }
        })

        // Cleanup to prevent memory leaks and double bindings
        return () => {
            sidebarButtons.forEach(button => {
                button.removeEventListener('click', handleMenuBtnClick);
            });
        };
    }, []);

    return (
        <div className="single-edit">
            <h2>{tutor ? 'Edit Tutor' : 'Add New Tutor'}</h2>
            <form onSubmit={handleSubmit}>
                <div className='single-inner-cont'>
                <label>School:</label>
                <CustomSelect 
                    name="select-school" 
                    placeholder="Select School"
                    options={schools}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'school', value: option.id } })}
                    selectedOption={schools.find(school => school.id === formData.school)}
                />

                <label>Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <label>User Email:</label>
                <span className="field-instruction">User email address to be associated with the tutor.</span>
                <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                </div>

                <div className="flex-row-buttons-cont">
                    <button onClick={() => { setView('list') }} className="grey-btn">Close</button>
                    <SaveButton
                        save={handleSubmit}
                        loading={loading}
                        textCreate="Add Tutor"
                        textCreateLoading="Saving..."
                        textCreateSuccess="Saved"
                        textCreateError="Error"
                        textUpdate="Update Tutor"
                        textUpdateLoading="Updating..."
                        textUpdateSuccess="Updated"
                        textUpdateError="Error"
                        isUpdating={!!tutor} 
                    />
                </div>

                {validationErrors.length > 0 &&
                    <div className="error-messages">
                        {validationErrors.map((error, index) => {
                            return <p key={index}>{error}</p>
                        })}
                    </div>
                }
            </form>
        </div>
    );
};

export default CrudTutorSingle;
