import React from "react";


const Bundle = ({levelKey, bundleKey, completed, label, updateStatus}) => {

    const toggleStatus = (levelKey, bundleKey, status) => {

        let newStatus = status;

        if (status == 'complete') {
            newStatus = false;
        } else if (status == 'in-progress') {
            newStatus = 'complete';
        } else {
            newStatus = 'in-progress';
        }

        updateStatus(levelKey, bundleKey, newStatus);
    }

    return (
        <div className={`bundle ${completed == 'complete' ? 'completed' : (completed == 'in-progress' ? 'in-progress' : '')}`} onClick={() => toggleStatus(levelKey, bundleKey, completed)}>
            <p>{label}</p>
        </div>
    )
}

export default Bundle;