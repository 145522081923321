import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../slices/authSlice";
import { Link } from "react-router-dom";

const LoginForm = () => {

    const saltRounds = 12;
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginMessage, setLoginMessage] = useState("");
    const {authError} = useSelector(state => state.auth);
    
    const handleLogin = async (e) => {

        e.preventDefault();

        if (email === "" || password === "") {
            setLoginMessage("Please enter your email and password");
            return;
        }

        dispatch(login({email: email, password: password}));
    }

    useEffect(() => {
        if (authError) {
            setLoginMessage(authError);
        }
    }, [authError])

    return (
        <div className="login">
            <h2>Student Tracking Portal Login</h2>

            <form className="login-form" onSubmit={handleLogin}>
                <input type="text" placeholder="Email address" className="email-input-icon" value={email} onChange={(e) => { setEmail(e.target.value); setLoginMessage(''); }} />
                <input type="password" placeholder="Password" className="password-input-icon" value={password} onChange={(e) => { setPassword(e.target.value); setLoginMessage(''); }}/>
                <button type="submit">Login</button>
                <span id="login-message">{loginMessage}</span>
                <Link to="/login/forgotten-password">Forgotten password?</Link>
            </form>
        </div>
    );
}

export default LoginForm;