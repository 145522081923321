import React, {useEffect, useState} from "react";
import { calcTotalLevel } from "../../includes/utilities";


const LevelDisplayEditable = ({area, topicIndex, levels, updateLevelProgress, startsAt}) => {

    const [levelTemplate] = useState([1,2,3,4,5,6]);
    const [displayLevels, setDisplayLevels] = useState([]);
    const [totalClass, setTotalClass] = useState('');
    const [totalLevel, setTotalLevel] = useState('');

    useEffect(() => {

        if (typeof levels == 'undefined') {
            return;
        }

        let newDisplayLevels = levelTemplate.map((level, i) => {
            return levels[i] || '';
        });

        setDisplayLevels(newDisplayLevels);

        const newTotalLevel = calcTotalLevel(levels, startsAt);

        if (newTotalLevel == 0) {
            setTotalClass('');
        } else if (newTotalLevel < 6) {
            setTotalClass('half-complete');
        } else {
            setTotalClass('complete');
        }

        setTotalLevel(newTotalLevel);

    }, [levels])

    const handleClick = (e) => {
        const boxClicked = parseInt(e.target.dataset.boxNum, 10);
        let newDisplayLevels = [...displayLevels];

        if (e.shiftKey) {
            for (let i = 0; i < boxClicked; i++) {
                if (newDisplayLevels[i] === '') {
                    newDisplayLevels[i] = 1;
                }
            }
        } else {
            if (newDisplayLevels[boxClicked - 1] === '') {
                newDisplayLevels[boxClicked - 1] = 0.5;
            } else if (newDisplayLevels[boxClicked - 1] === 0.5) {
                newDisplayLevels[boxClicked - 1] = 1;
            } else {
                newDisplayLevels[boxClicked - 1] = '';
            }
        }

        // const newLevel = newDisplayLevels.reduce((acc, val, idx) => {
        //     if (val === '1') return idx + 1;
        //     if (val === '0.5') return idx + 0.5;
        //     return acc;
        // }, 0);

        setDisplayLevels(newDisplayLevels);
        updateLevelProgress(area, topicIndex, newDisplayLevels);
    };

    return (
        <div className="level-display-cont editable">
            {displayLevels.length > 0 && displayLevels.map((figure, i) => (
                <span className={`level ${i+1} ${figure == '1' ? 'complete' : figure == '0.5' ? 'half-complete' : '' } ${i + 1 < startsAt && 'disabled'}`} data-box-num={i+1} onClick={(e) => handleClick(e)}>{figure}</span>
            ))}

            <span className={`level total ${totalClass}`}>
                {totalLevel}
            </span>
        </div>
    )
}

export default LevelDisplayEditable;