import React, { useState, useEffect } from 'react';
import { getSchools } from '../../services/scpApi';
import dotsVertical from '../../assets/dots-vertical.svg';
import CrudSchoolsListFilters from './CrudSchoolListFilters';

const CrudSchoolList = ({ setSelectedSingleItemId, setView }) => {

    const [filters, setFilters] = useState({
        school: null
    });
    const [schools, setSchools] = useState([]);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    // fetch schools
    useEffect(() => {
        (async () => {
            if (!schools) {
                setMessage('Please select a school');
                return;
            }

            try {
                // fetch schools data
                const data = await getSchools({
                    schoolId: filters.school ? filters.school.id : null
                });

                // collect the relevant data from the response
                const fetchedSchools = data.map(school => ({
                    id: school.id,
                    name: school.name,
                    address: school.address,
                    website: school.website
                }));

                // set the students data
                setSchools(fetchedSchools);
                setMessage(fetchedSchools.length > 0 ? '' : 'No schools found');
            } catch (error) {
                console.error('Error fetching schools:', error);
                setMessage('Error fetching schools');
                setSchools([]);
            }
        })();

    }, [filters, currentPage]);

    const handleEditClick = (school) => {
        setSelectedSingleItemId(school.id);
        setView('single');
    };

    const clearFilters = () => {
        setFilters({
            school: null
        });
    }

    const columns = [
        { columnLabel: 'Name', dataKey: 'name' },
        { columnLabel: 'Address', dataKey: 'address' },
        { columnLabel: 'Website', dataKey: 'website' }
    ];

    return (
        <div className="">
            <div className="separator"></div>
            <div className='crud-list'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.columnLabel}>{column.columnLabel}</th>
                            ))}
                            <th>View / Edit</th>
                        </tr>
                        <tr className="separator-row"></tr>
                    </thead>

                    <tbody>
                        {message ? (
                            <tr>
                                <td colSpan={columns.length + 1}>{message}</td>
                            </tr>
                        ) : (
                            schools.length > 0 ? (
                                schools.map(school => (
                                    <tr key={school.id}>
                                        {columns.map(column => (
                                            <td key={column.dataKey}>{school[column.dataKey]}</td>
                                        ))}
                                        <td className='edit-dots'>
                                            <button
                                                onClick={() => handleEditClick(school)}
                                                className='edit-dots'>
                                                <img src={dotsVertical} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length + 1}>No schools found</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default CrudSchoolList;