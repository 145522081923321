import React, { useState, useEffect, useRef } from "react";
import CustomSelect from "../../features/customSelect/CustomSelect";
import CustomMultiSelect from "../../features/customMultiSelect/CustomMultiSelect";
import CustomCheckbox from "../../features/customCheckbox/CustomCheckbox";
import CustomMultiSelectPeriod from "../../features/customMultiSelect/CustomMultiSelectPeriod";
import GroupReportBarChart from "../../features/groupReportBarChart/GroupReportBarChart";
import GroupReportsPDF from "./GroupReportsPdf";
import { yearGroups, studentStatuses } from "../../data/data";
import { getSchools, getTutors, getTutorGroups, getStudents } from "../../services/scpApi";
import { calcAcademicYearInterventionCompleteness, calcHadSomeLowAttendance } from "../../includes/utilities";


const GroupReports = () => {

    // schools
    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState({});

    // year groups
    const [selectedYearGroup, setSelectedYearGroup] = useState({});

    // tutor group leads
    const [tutorGroupLeads, setTutorGroupLeads] = useState([]);
    const [selectedTutorGroupLead, setSelectedTutorGroupLead] = useState({});

    // tutor groups
    const [tutorGroups, setTutorGroups] = useState([]);
    const [selectedTutorGroup, setSelectedTutorGroup] = useState({});

    // Exclude partial academic yr intervention students
    const [includeFullYrIntervention, setIncludeFullYrIntervention] = useState(true);

    // Solely include partial academic yr intervention students
    const [includePartialYrIntervention, setIncludePartialYrIntervention] = useState(false);

    // students and pupil_periods
    const [students, setStudents] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [selectedPeriods, setSelectedPeriods] = useState([]);

    // student status (multi select)
    const [selectedStudentStatuses, setSelectedStudentStatuses] = useState([]);

    // include low absenteeism
    const [includeNormalAttendance, setIncludeNormalAttendance] = useState(true);

    // include medium & high absenteeism
    const [includeLowAttendance, setIncludeLowAttendance] = useState(false);

    // report validation message
    const [reportValidationMessage, setReportValidationMessage] = useState("");

    // report data
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [reportData, setReportData] = useState([]);

    // pdf
    const [pdfReady, setPdfReady] = useState(false);
    const groupReportChartRef = useRef();

    // get schools
    useEffect(() => {
        (async () => {
            try {
                const response = await getSchools();
                setSchools(response);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])

    // get tutors when school is selected
    useEffect(() => {
        if (!selectedSchool.id) {
            return;
        }

        (async () => {
            try {
                const response = await getTutors(selectedSchool.id);
                setTutorGroupLeads(response);

                // reset selected dependent fields
                setSelectedTutorGroup({});
                setSelectedTutorGroupLead({});
                setTutorGroups([]);
                setReportValidationMessage("");
            } catch (error) {
                console.log(error);
            }
        })();
    }, [selectedSchool])

    // get tutor groups when tutor group lead is selected
    useEffect(() => {
        if (!selectedTutorGroupLead.id) {
            return;
        }

        (async () => {
            try {
                const tutorGroupsData = await getTutorGroups({
                    tutorId: selectedTutorGroupLead.id
                });

                // prefix tutor group name with tutor name
                tutorGroupsData.forEach(group => {
                    group.name = `${group.tutor.name} - ${group.name}`;
                });

                // sort alphabetically
                tutorGroupsData.sort((a, b) => a.name.localeCompare(b.name));

                setTutorGroups(tutorGroupsData);

                // reset selected dependent fields
                setSelectedTutorGroup({});
                setReportValidationMessage("");
            } catch (error) {
                console.log(error);
            }
        })();
    }, [selectedTutorGroupLead])

    // get students when school, year group, tutor group or periods are selected
    useEffect(() => {
        if (!selectedSchool.id) {
            return;
        }

        // prepare fetch args
        const args = {
            schoolId: selectedSchool.id,
            includePeriods: true
        };

        // year group
        if (selectedYearGroup.year) {
            args.yearGroup = selectedYearGroup.year;
        }

        // all tutor's tutor groups if not selected and tutor selected
        if (selectedTutorGroupLead.id && !selectedTutorGroup.id) {
            args.tutorGroupId = tutorGroups.map((group) => group.id);
        }

        // selected tutor group if is selected
        if (selectedTutorGroup.id) {
            args.tutorGroupId = selectedTutorGroup.id;
        }

        // fetch
        (async () => {
            try {
                const response = await getStudents(args);
                setStudents(response);
            } catch (error) {
                console.log(error);
            }
        })();

    }, [selectedSchool, selectedYearGroup, selectedTutorGroupLead, selectedTutorGroup])

    // calc selectable periods when students are set
    useEffect(() => {
        generateReport();

        if (!students[0] || !students[0].pupil_periods) {
            return;
        }

        const newPeriods = students.reduce((acc, student) => {
            student.pupil_periods.forEach((period) => {

                // Check if the period already exists in the accumulator
                const exists = acc.some(collectedPeriod => 
                    collectedPeriod.calendar_year === period.calendar_year && 
                    collectedPeriod.period_name === period.period_name
                );

                // Add if it doesn't
                if (!exists) {
                    acc.push({
                        calendar_year: period.calendar_year,
                        period_name: period.period_name,
                        academic_year: period.academic_year
                    });
                }
            });

            return acc;
        }, []);

        setPeriods(newPeriods);
    }, [students])

    // generate report when other filters change
    useEffect(() => {
        generateReport();
    }, [includeFullYrIntervention, includePartialYrIntervention, includeNormalAttendance, includeLowAttendance, selectedStudentStatuses])


    // multi select student status
    const toggleStudentStatusSelection = (status) => {
        if (!status) {
            setSelectedStudentStatuses([]);
        }

        setSelectedStudentStatuses((prevStatuses) => {
            if (prevStatuses.find((s) => s.status === status.status)) {
                // remove status from selected statuses
                return prevStatuses.filter((s) => s.status !== status.status);
            } else {
                // add status to selected statuses
                return [...prevStatuses, status];
            }
        });
    }

    // multi select periods
    const updateSelectedPeriods = (period) => {
        if (!period) {
            setSelectedPeriods([]);
        }

        setSelectedPeriods((prevSelectedPeriods) => {
            if (prevSelectedPeriods.find((s) => s.calendar_year === period.calendar_year && s.period_name === period.period_name)) {
                // remove period from selected periods
                return prevSelectedPeriods.filter((s) => s.calendar_year !== period.calendar_year || s.period_name !== period.period_name);
            } else {
                // add period to selected periods
                return [...prevSelectedPeriods, period];
            }
        });
    }

    // drop selected periods when periods change
    useEffect((i) => { 
        if (periods.length < 1) {
            setSelectedPeriods([]);
        } else {
            // set the last period
            setSelectedPeriods([periods[periods.length - 1]]);
        }
    }, [periods])

    // generate the report
    const generateReport = () => {

        let newSelectedStudents = [];

        // validate report filters
        if (!selectedSchool.id) {
            setReportValidationMessage("Please select a school");
            return;
        }

        if (selectedPeriods.length < 1) {
            setReportValidationMessage("Please select reporting periods");
            return;
        }

        students.forEach((student) => {

            const filters = {
                includeForTutor: false,
                includeForCompleteness: false,
                includeForAttendance: false,
                includeForStatus: true
            };

            const completeness = calcAcademicYearInterventionCompleteness(student.pupil_periods, selectedPeriods)
            const hadSomeLowAttendance = calcHadSomeLowAttendance(student.pupil_periods, selectedPeriods);

            if (includeFullYrIntervention) {
                if (completeness == 'complete') {
                    filters.includeForCompleteness = true;
                }
            }
    
            // include partial year intervention
            if (includePartialYrIntervention) {
                if (completeness == 'partial') {
                    filters.includeForCompleteness = true;
                }
            }
    
            // include normal attendance (full attendance)
            if (includeNormalAttendance) {
                if (!hadSomeLowAttendance) {
                    filters.includeForAttendance = true;
                }
            }
    
            // include students with some low attendance
            if (includeLowAttendance) {
                if (hadSomeLowAttendance) {
                    filters.includeForAttendance = true;
                }
            }

            // exclude students with any status outside of selected statuses (if statuses are selected)
            if (selectedStudentStatuses.length > 0) {
                let statusMatch = false;

                student.pupil_periods.forEach((period) => {
                    selectedStudentStatuses.forEach((status) => {
                        if (period.pupil_status === status.status) {
                            statusMatch = true;
                        }
                    });
                })

                if (!statusMatch) {
                    filters.includeForStatus = false;
                }
            }

            if (filters.includeForAttendance && filters.includeForCompleteness && filters.includeForStatus) {
                newSelectedStudents.push(student);
            }
        });

        if (newSelectedStudents.length < 1) {
            setReportValidationMessage("No students meet the selected criteria");
        } else {
            setReportValidationMessage('');
        }

        setSelectedStudents(newSelectedStudents);
    }

    // prepare report data
    useEffect(() => {

        if (!selectedStudents || selectedStudents.length < 1) {
            return;
        }

        let newReportData = [];

        newReportData = [...selectedStudents];

        setReportData(newReportData);

    }, [selectedStudents])

    // reset when filters change
    useEffect(() => {
        setPdfReady(false);
    }, [selectedStudents])

    // clear filters
    const clearFilters = () => {
        setSelectedSchool({});
        setSelectedYearGroup({});
        setSelectedTutorGroupLead({});
        setSelectedTutorGroup({});
        setSelectedStudentStatuses([]);
        setSelectedPeriods([]);
        setIncludeNormalAttendance(true);
        setIncludeLowAttendance(false);
        setIncludeFullYrIntervention(true);
        setIncludePartialYrIntervention(false);
        setReportValidationMessage("");
        setSelectedStudents([]);
    }

    return (
        <div className="group-reports">
            <h1>Group Reports</h1>

            <div className="full-width-select-cont">
                <CustomSelect
                    name="select-school" 
                    placeholder="Select School"
                    options={schools}
                    labelKey="name"
                    setSelectedOption={(option) => setSelectedSchool(option)}
                    selectedOption={selectedSchool}
                />
            </div>

            <div class="separator"></div>

            <div className="select-report-filters-cont">
                <h3>Select Report Filters</h3>

                <div className="flex-row-select-cont">
                    <CustomSelect
                        name="select-year-group" 
                        placeholder="Select Year Group"
                        options={yearGroups}
                        labelKey="name"
                        setSelectedOption={(option) => setSelectedYearGroup(option)}
                        selectedOption={selectedYearGroup}
                    />

                    <CustomSelect
                        name="select-tutor-group-lead" 
                        placeholder="Select tutor group lead"
                        options={tutorGroupLeads}
                        labelKey="name"
                        setSelectedOption={(option) => setSelectedTutorGroupLead(option)}
                        selectedOption={selectedTutorGroupLead}
                    />

                    <CustomSelect
                        name="select-tutor-group" 
                        placeholder="Select tutor group"
                        options={tutorGroups}
                        labelKey="name"
                        setSelectedOption={(option) => setSelectedTutorGroup(option)}
                        selectedOption={selectedTutorGroup}
                    />
                </div>

                <div className="full-width-select-cont">
                    <CustomMultiSelectPeriod
                        name="period-select" 
                        placeholder="Select reporting periods"
                        options={periods}
                        setSelectedOption={(period) => updateSelectedPeriods(period)}
                        selectedOptions={selectedPeriods}
                    />
                </div>

                <div className="flex-row-checkbox-cont">

                    <CustomCheckbox 
                        name="include-full-yr-intervention" 
                        label="Include full year intervention students" 
                        checked={includeFullYrIntervention} 
                        setChecked={(newStatus) => setIncludeFullYrIntervention(newStatus)}
                    />

                    <CustomCheckbox 
                        name="include-partial-yr-intervention" 
                        label="Include partial year intervention students" 
                        checked={includePartialYrIntervention} 
                        setChecked={(newStatus) => setIncludePartialYrIntervention(newStatus)}
                    />
                </div>

                <div className="flex-row-select-cont">
                    <CustomMultiSelect
                        name="select-student-status" 
                        placeholder="Select student status"
                        options={studentStatuses}
                        labelKey="name"
                        setSelectedOption={(option) => toggleStudentStatusSelection(option)}
                        selectedOptions={selectedStudentStatuses}
                    />
                </div>

                <div className="flex-row-checkbox-cont">
                    <CustomCheckbox 
                        name="checkbox-normal-attendance" 
                        label="Include normal attendance marked students" 
                        checked={includeNormalAttendance} 
                        setChecked={(newStatus) => setIncludeNormalAttendance(newStatus)}
                    />

                    <CustomCheckbox 
                        name="checkbox-include-low-attendance" 
                        label="Include low attendance marked students" 
                        checked={includeLowAttendance} 
                        setChecked={(newStatus) => setIncludeLowAttendance(newStatus)}
                    />
                </div>

                <a onClick={() => clearFilters()} className="clear-filters-btn">Clear Filters</a>
                <button onClick={() => generateReport()}>Generate Report</button>
                <span className="generate-report-validation-msg">{reportValidationMessage}</span>
            </div>

            <div class="separator"></div>
            

            {selectedStudents && selectedStudents.length > 0 &&
                <>
                    <div>

                        <div className="group-report-details-title">
                            <h2>Group Report</h2>
                        </div>

                        <ul className="report-details-list">
                            <li><b>School</b> - {selectedSchool && selectedSchool.name && selectedSchool.name}</li>
                            {selectedTutorGroupLead && selectedTutorGroupLead.name &&
                                <li>
                                    <b>Intervention lead</b> - <span>{selectedTutorGroupLead.name}</span>
                                </li>
                            }
                            {selectedTutorGroup && selectedTutorGroup.name &&
                                <li>
                                    <b>Tutor Group</b> - <span>{selectedTutorGroup.name}</span>
                                </li>
                            }
                            {selectedYearGroup && selectedYearGroup.year &&
                                <li>
                                    <b>Year Group</b> - <span>{selectedYearGroup.year}</span>
                                </li>
                            }
                            {selectedStudentStatuses && selectedStudentStatuses.length > 0 &&
                                <li>
                                    <b>Student Statuses</b> - 
                                    {selectedStudentStatuses && selectedStudentStatuses[0] && selectedStudentStatuses.map((selectedStatus, index) => 
                                        <>
                                        <span>{selectedStatus.name} {index !== selectedStudentStatuses.length && ', '}</span>
                                        </>
                                    )}
                                </li>
                            }
                            <li><b>Reporting Periods</b> - 
                                {selectedPeriods && selectedPeriods[0] && selectedPeriods.map((selectedPeriod, index) => 
                                    <>
                                    <span>{selectedPeriod.period_name} - {selectedPeriod.calendar_year} {index !== selectedPeriods.length && ', '}</span>
                                    </>
                                )}
                            </li>
                        </ul>

                        <div ref={groupReportChartRef}>
                            <GroupReportBarChart 
                                selectedStudents={selectedStudents} 
                                selectedPeriods={selectedPeriods}
                            />
                        </div>
                    </div>

                    <GroupReportsPDF
                        pdfReady={pdfReady}
                        setPdfReady={(ready) => setPdfReady(ready)}
                        groupReportChartRef={groupReportChartRef}
                        selectedPeriods={selectedPeriods}
                        selectedSchool={selectedSchool}
                        selectedStudentStatuses={selectedStudentStatuses}
                        includeLowAttendance={includeLowAttendance}
                        selectedTutorGroupLead={selectedTutorGroupLead}
                        selectedTutorGroup={selectedTutorGroup}
                    />
                </>
            }

            
        </div>
    );
}

export default GroupReports;