import React, { useState, useEffect } from "react";
import { getSchool } from '../../services/scpApi';
import CrudSchoolList from "../../features/crudSchool/CrudSchoolList";
import CrudSchoolSingle from "../../features/crudSchool/CrudSchoolSingle";

const ManageSchools = () => {

    const [view, setView] = useState("list");
    const [selectedSingleItemId, setSelectedSingleItemId] = useState(null);
    const [selectedSchool, setSchool] = useState(null);
    const [loading, setLoading] = useState(null);

    const handleAddNew = () => {
        setView("single");
        setSelectedSingleItemId(null);
        setSchool(null);
    };

    const handleViewChange = ({isBackToList = false}) => {
        console.log(isBackToList);
        if (isBackToList) {
            setView("list");
            return;
        }

        setView(view === "list" ? "single" : "list");
    };

    const refreshSelectedSchool = () => {
        if (!selectedSingleItemId) {
            return;
        }
        (async () => {
            try {
                const data = await getSchool(selectedSingleItemId);
                setSchool(data.school);
            } catch (error) {
                console.error('Error fetching student:', error);
            }
        })();
    }

    useEffect(() => {
        (async () => {
            if (!selectedSingleItemId) {
                return;
            }
            try {
                // fetch student data
                const data = await getSchool(selectedSingleItemId);
                setSchool(data.school);
            } catch (error) {
                console.error('Error fetching school:', error);
            }
        })();
    }, [selectedSingleItemId]);

    return (
        <div className="crud-page">
            <h1>Manage Schools</h1>

            {view === "list" ? (
                <>
                    <button onClick={handleAddNew} className="add-new">Add New School</button>
                    <CrudSchoolList setSelectedSingleItemId={setSelectedSingleItemId} setView={handleViewChange} />
                </>
            ) : (
                <CrudSchoolSingle 
                    loading={loading}
                    setLoading={setLoading}
                    school={selectedSchool}
                    setSchool={setSchool} 
                    setView={handleViewChange} 
                    refreshSelectedSchool={refreshSelectedSchool} 
                />
            )}
        </div>
    );
};

export default ManageSchools;