import React, { useState, useEffect } from 'react';
import CustomSelect from "../customSelect/CustomSelect";
import { getStudents, getSchools, getTutorGroups } from "../../services/scpApi";
import { yearGroups } from "../../data/data";

const CrudStudentListFilters = ({ filters, setFilters }) => {

    // filter options
    const [schools, setSchools] = useState([]);
    const [tutorGroups, setTutorGroups] = useState([]);

    // Fetch schools data
    useEffect(() => {
        (async () => {
            try {
                const schoolsData = await getSchools();
                setSchools(schoolsData);

                // set the first school in filters
                if (!filters.school && schoolsData.length > 0) {
                    setFilters(prevFilters => ({ ...prevFilters, school: schoolsData[0] }));
                }
            } catch (error) {
                console.error('Error fetching schools:', error);
            }
        })();
    }, []);

    // Fetch tutor groups when school is selected
    useEffect(() => {
        if (!filters.school?.id) return;

        const fetchTutorGroups = async () => {
            try {
                const tutorGroupsData = await getTutorGroups({
                    schoolId: filters.school.id
                });

                // prefix tutor group name with tutor name
                tutorGroupsData.forEach(group => {
                    group.name = `${group.tutor.name} - ${group.name}`;
                });

                // sort alphabetically
                tutorGroupsData.sort((a, b) => a.name.localeCompare(b.name));

                setTutorGroups(tutorGroupsData);
            } catch (error) {
                console.error('Error fetching tutor groups:', error);
            }
        };
        
        fetchTutorGroups();
    }, [filters.school]);

    const handleSchoolChange = (selectedSchool) => {
        setFilters(prevFilters => ({ ...prevFilters, school: selectedSchool}));
    };

    const handleYearGroupChange = (selectedYearGroup) => {
        setFilters(prevFilters => ({ ...prevFilters, yearGroup: selectedYearGroup }));
    };

    const handleTutorGroupChange = (selectedTutorGroup) => {
        setFilters(prevFilters => ({ ...prevFilters, tutorGroup: selectedTutorGroup }));
    };

    return (
        <div className="flex-row-select-cont">
            <CustomSelect
                name="filter-school"
                placeholder="Filter by School"
                options={schools}
                labelKey="name"
                setSelectedOption={(option) => handleSchoolChange(option)}
                selectedOption={filters.school}
            />
            <CustomSelect
                name="filter-year-group"
                placeholder="Filter by Year Group"
                options={yearGroups}
                labelKey="name"
                setSelectedOption={(option) => handleYearGroupChange(option)}
                selectedOption={filters.yearGroup}
            />
            <CustomSelect
                name="filter-tutor-group"
                placeholder="Filter by Tutor Group"
                options={tutorGroups}
                labelKey="name"
                setSelectedOption={(option) => handleTutorGroupChange(option)}
                selectedOption={filters.tutorGroup}
            />
        </div>
    );
};

export default CrudStudentListFilters;
