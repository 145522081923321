import React, { useState, useEffect } from "react";
import { getTutorGroup } from '../../services/scpApi';
import CrudTutorGroupSingle from "../../features/crudTutorGroup/CrudTutorGroupSingle";
import CrudTutorGroupList from "../../features/crudTutorGroup/CrudTutorGroupList";

const ManageTutorGroups = () => {

    const [view, setView] = useState("list"); 
    const [selectedSingleItemId, setSelectedSingleItemId] = useState(null);
    const [selectedTutorGroup, setTutorGroup] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleAddNew = () => {
        setView("single");
        setSelectedSingleItemId(null);
        setTutorGroup(null);
    };

    const handleViewChange = ({isBackToList = false}) => {
        console.log(isBackToList);
        if (isBackToList) {
            setView("list");
            return;
        }
        
        setView(view === "list" ? "single" : "list");
    };

    const refreshSelectedTutorGroup = () => {
        if (!selectedSingleItemId) {
            return;
        }
        (async () => {
            try {
                const data = await getTutorGroup({
                    id: selectedSingleItemId
                });
                setTutorGroup(data.tutor_groups);
            } catch (error) {
                console.error('Error fetching tutor:', error);
            }
        })();
    }

    useEffect(() => {
        (async () => {
            if (!selectedSingleItemId) {
                return;
            }
            try {
                // fetch tutor data
                const data = await getTutorGroup({
                    id: selectedSingleItemId
                });
                setTutorGroup(data.tutor_groups);
            } catch (error) {
                console.error('Error fetching tutor:', error);
            }
        })();
    }, [selectedSingleItemId]);

    return (
        <div className="crud-page">
            <h1>Manage Tutor Groups</h1>

            {view === "list" ? (
                <>
                    <button onClick={handleAddNew} className="add-new">Add New Tutor Group</button>
                    <CrudTutorGroupList setSelectedSingleItemId={setSelectedSingleItemId} setView={handleViewChange} />
                </>
            ) : (
                <CrudTutorGroupSingle 
                    loading={loading} 
                    tutorGroup={selectedTutorGroup}
                    setTutorGroup={setTutorGroup} 
                    setView={handleViewChange} 
                    refreshSelectedTutorGroup={refreshSelectedTutorGroup}
                    setLoading={setLoading}
                />
            )}
        </div>
    );
};

export default ManageTutorGroups;