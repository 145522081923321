// Auth token
export function getAuthToken() {
    return localStorage.getItem('SCP_AUTH_TOKEN');
}

export function setAuthToken(authToken) {
    localStorage.setItem('SCP_AUTH_TOKEN', authToken);
}

export function removeAuthToken() {
    localStorage.removeItem('SCP_AUTH_TOKEN');
}

// Auth user
export function getAuthUser() {
    return localStorage.getItem('SCP_AUTH_USER');
}

export function setAuthUser(authUser) {
    localStorage.setItem('SCP_AUTH_USER', authUser);
}

export function removeAuthUser() {
    localStorage.removeItem('SCP_AUTH_USER');
}