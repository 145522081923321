import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../slices/authSlice';


const User = () => {

    const user = useSelector(state => state.auth.authUser);
    const dispatch = useDispatch();
    const [userInitials, setUserInitials] = useState('?');

    useEffect(() => {
        if (user.name) {
            const initials = user.name.split(' ').map(name => name.charAt(0)).join('');
            setUserInitials(initials);
        }
    }, [user]);

    const handleLogout = () => {
        dispatch(logout());
    };
    

    return (
        <div className="user">
            <div className="userAvatar">
                <span>{userInitials}</span>
            </div>

            {user.name && (
                <div className="userName">
                    <span>{user.name}</span>
                </div>
            )}

            {user && (
                <a onClick={handleLogout} className="log-out">Log out</a>
            )}
        </div>
    );
}

export default User;