import React from "react";
import { Routes, Route, Outlet } from 'react-router-dom';
import PortalLayout from "../../layouts/PortalLayout";
import Dashboard from "../dashboard/Dashboard";
import UpdateStudentProgress from "../updateStudentProgress/UpdateStudentProgress";
import StudentProgress from "../studentProgress/StudentProgress";
import GroupReports from "../groupReports/GroupReports";
import ManageStudents from "../manageStudents/ManageStudents";
import ManageTutors from "../manageTutors/ManageTutors";
import ManageUsers from "../manageUsers/ManageUsers";
import ManageTutorGroups from "../manageTutorGroups/ManageTutorGroups";
import ManageSchools from "../manageSchools/ManageSchools";


const Portal = () => {

    return (
        <PortalLayout>
            <Routes>
                <Route path="/update-student-progress" element={<UpdateStudentProgress />} />
                <Route path="/student-progress" element={<StudentProgress />} />
                <Route path="/group-reports" element={<GroupReports />} />
                <Route path="/manage-students" element={<ManageStudents />} />
                <Route path="/manage-tutors" element={<ManageTutors />} />
                <Route path="/manage-users" element={<ManageUsers />} />
                <Route path="/manage-tutor-groups" element={<ManageTutorGroups />} />
                <Route path="manage-schools" element={<ManageSchools />} />
                <Route path="/" element={<Dashboard />} />
            </Routes>

            <Outlet />
        </PortalLayout>
    );
}

export default Portal;