import React from "react";
import LoginLayout from "../../layouts/LoginLayout";
import PasswordResetForm from "../../features/passwordReset/PasswordResetForm";

const PasswordResetPage = () => {
    return (
        <LoginLayout>
            
            <h1>The School Consultancy Programme</h1>
            <h2>Student tracking pupil</h2>

            <div className="separator"></div>

            <PasswordResetForm />

        </LoginLayout>
    );
}

export default PasswordResetPage;