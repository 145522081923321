import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { calcTotalLevel } from '../../includes/utilities';

const ProgressBarChart = ({selectedPeriods}) => {

    const [assessments, setAssessments] = useState([]);
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {

        if (!selectedPeriods || selectedPeriods.length < 1) {
            return;
        }

        const newLabels = [];
        const newDatasets = [];

        console.log('selectedPeriods', selectedPeriods);

        // for each selected period
        selectedPeriods.forEach((period, i) => {

            if (!period.assessment) {
                return;
            }

            const data = [];

            Object.keys(period.assessment).forEach((areaKey) => {
                period.assessment[areaKey].topics.forEach((topic, topicIndex) => {
    
                    // only include if there has been change from the first period
                    //if (selectedPeriods[0][area].topics[topic].level !== topic.level) {
                        // collect labels first time round
                        if (i === 0) {
                            newLabels.push(topic.label);
                        }

                        // calc total level for this topic
                        const level = calcTotalLevel(topic.level, topic['starts-at'])

                        // collect data
                        data.push(level);
                    //}
                })
            })

            newDatasets.push({
                label: `${period.period_name} ${period.calendar_year}`,
                data: data,
                backgroundColor: (context) => {
                    if (i === selectedPeriods.length - 1) {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 350);
                        gradient.addColorStop(0, "rgba(53, 158, 89, 1)");
                        gradient.addColorStop(1, "rgba(53, 158, 89, 0.4)");
                        return gradient;
                    } else {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 350);
                        gradient.addColorStop(0, "rgba(53, 158, 89, 0.8)");
                        gradient.addColorStop(1, "rgba(53, 158, 89, 0.2)");
                        return gradient;
                    }
                },
                borderColor: 'rgba(60, 80, 99, 0.3)',
                borderWidth: 1,
                borderRadius: 6
            });
        });

        console.log('newLabels', newLabels);
        console.log('newDatasets', newDatasets);

        setLabels(newLabels);
        setDatasets(newDatasets);

    }, [selectedPeriods])

    return (
        <>
            {labels && datasets && 
                <Bar
                    data={{
                        labels: labels,
                        datasets: datasets
                    }}
                    options={{
                        scales: {
                            y: {
                                max: 6,
                                title: {
                                    display: true,
                                    text: 'Level'
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: true
                            }
                        }
                    }}
                />
            }
        </>
    )
}

export default ProgressBarChart;