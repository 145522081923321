import React from "react";
import bin from '../../assets/bin.svg';

const Repeater = ({ items, setItems, headings, formRow, buttonContent, handleChange }) => {

    const handleChangeInternal = (e, index, isDate = false) => {
        const newItems = [...items];
        const { name, value } = isDate ? e : e.target;

        if (isDate) {
            newItems[index] = {
                ...newItems[index],
                [name]: value
            };
        } else {
            newItems[index] = {
                ...newItems[index],
                [name]: value
            };
        }
        setItems(newItems);
    };

    const addItem = () => {
        const emptyItem = {};
        if (items.length > 0) {
            Object.keys(items[0]).forEach((key) => {
                emptyItem[key] = '';
            });
        }
        setItems([...items, emptyItem]);
    };

    const deleteItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    return (
        <div className="repeater-cont">
            <table>
                <thead>
                    <tr>
                        {headings.map((heading, index) => (
                            <th key={index}>{heading}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {items && items.map((item, index) => (
                        <tr key={index}>
                            {formRow(item, index, (e, isDate) => (handleChange ? handleChange(e, index, isDate) : handleChangeInternal(e, index, isDate)))}
                            <td>
                                <img className='contacts-bin-icon' src={bin} alt="rubbish bin icon" onClick={() => deleteItem(index)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" onClick={addItem}>{buttonContent}</button>
        </div>
    );
};


export default Repeater;
