import React, { useState, useEffect } from 'react';
import { toPng } from 'html-to-image';
import { Document, Page, Text, View, Image, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';


const GroupReportsPDF = ({pdfReady, setPdfReady, selectedSchool, selectedPeriods, groupReportChartRef, selectedStudentStatuses, includeLowAttendance, selectedTutorGroupLead, selectedTutorGroup}) => {

    const [pdfLoading, setPdfLoading] = useState(false);
    const [groupReportChart, setGroupReportChart] = useState();
    Font.register({ family: 'Poppins', src: 'https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf' });


    const generatePdf = () => {
        setPdfLoading(true);
        captureImages();
        setPdfReady(true);
    }

    useEffect(() => {
        if (pdfReady) {
            setPdfLoading(false);
        }
    }, [pdfReady])

    const captureImages = () => {
        toPng(groupReportChartRef.current)
            .then((dataUrl) => {
                setGroupReportChart(dataUrl);
            })
            .catch((error) => {
                console.error('Could not capture group report chart image', error);
            });
    }
    
    // PDF Styles
    const pdfStyles = StyleSheet.create({
        title: { fontFamily: 'Poppins', fontWeight: 700, color: '#3C5063' },
        text: { fontFamily: 'Poppins', fontWeight: 700, color: '#3C5063', fontSize: 10 },
        textUnderline: { fontFamily: 'Poppins', fontWeight: 700, color: '#3C5063', fontSize: 12, textDecoration: 'underline' },
        page: { flexDirection: 'column', backgroundColor: '#E4E4E4' },
        section: { marginLeft: '30px', marginRight: '30px', paddingTop: '25px', paddingBottom: '25px' },
        image: { objectFit: 'scale-down', paddingTop: 25, paddingRight: 3, paddingBottom: 25, paddingLeft: 3},
        imageCont: { maxHeight: '75vh', maxWidth: '100vw' },
        detailsSection: {paddingLeft: 3, paddingRight: 3},
        singleOutcome: { marginTop: 10 }
    });

    // The PDF document component
    const PdfDocument = () => (
        <Document>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <View style={pdfStyles.detailsSection}>

                        <Text style={pdfStyles.text}>School: {selectedSchool && selectedSchool.name && selectedSchool.name}</Text>

                        {selectedStudentStatuses && selectedStudentStatuses.length > 0 &&
                            <Text style={pdfStyles.text}>Student statuses: 
                                {selectedStudentStatuses.map((status, index) => 
                                    <Text style={pdfStyles.text}> {status} {index !== selectedStudentStatuses.length - 1 && ', '}</Text>
                                )}
                            </Text>
                        }

                        {includeLowAttendance && <Text style={pdfStyles.text}>Attendance: Includes Low Attendance students</Text>}

                        {selectedTutorGroupLead && selectedTutorGroupLead.name && <Text style={pdfStyles.text}>Tutor Group Lead: {selectedTutorGroupLead.name}</Text>}

                        {selectedTutorGroup && selectedTutorGroup.name && <Text style={pdfStyles.text}>Tutor Group: {selectedTutorGroup.name}</Text>}

                        <Text style={pdfStyles.text}>Reporting Periods - 
                            {selectedPeriods && selectedPeriods[0] && selectedPeriods.map((selectedPeriod, index) => 
                                <Text style={pdfStyles.text}> {selectedPeriod.period_name} {selectedPeriod.calendar_year} {index !== selectedPeriods.length - 1 && ', '}</Text>
                            )}
                        </Text>

                    </View>
                    <View style={pdfStyles.imageCont}>
                        {groupReportChart && <Image style={pdfStyles.image} src={groupReportChart} />}
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            {groupReportChart && pdfReady ? (
                <PDFDownloadLink
                    document={
                        <PdfDocument />
                    }
                    fileName="complex_components.pdf"
                    className="mainButton pdfButton"
                >
                    {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download PDF')}
                </PDFDownloadLink>
            ) : (
                <button onClick={generatePdf} className="pdfButton" disabled={pdfLoading}>{pdfLoading ? 'Generating PDF...' : 'Generate PDF'}</button>
            )}
        </>
    )
}

export default GroupReportsPDF;