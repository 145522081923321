import React, { useState, useEffect, useRef } from "react";
import { getStudent, updatePupilPeriod } from "../../services/scpApi";
import SelectStudent from "../../features/selectStudent/SelectStudent";
import CustomMultiSelectPeriod from "../../features/customMultiSelect/CustomMultiSelectPeriod";
import ProgressTable from "../../features/progressTable/ProgressTable";
import ProgressBarChart from "../../features/progressBarChart/ProgressBarChart";
import ProgressOutcomeStatements from "../../features/progressOutcomeStatements/ProgressOutcomeStatements";
import { calcBundlesWithIntervention, calcPreviousPeriod } from "../../includes/utilities";
import StudentProgressPDF from "./StudentProgressPdf";


const StudentProgress = () => {

    const [selectedStudent, setSelectedStudent] = useState({}); // the selected student from the dropdown
    const [student, setStudent] = useState({}); // data for the selected student
    const [currentPeriod, setCurrentPeriod] = useState();
    const [selectablePeriods, setSelectablePeriods] = useState([]);
    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const [bundlessWithIntervention, setBundllesWithIntervention] = useState([]);
    const [pdfReady, setPdfReady] = useState(false);
    const progressTableRef = useRef();
    const progressBarChartRef = useRef();
    

    useEffect(() => {

        if (!selectedStudent.id) {
            setStudent({});
            setCurrentPeriod();
            setSelectedPeriods([]);
            return;
        }

        setSelectedPeriods([]);

        (async () => {
            try {
                const studentData = await getStudent(selectedStudent.id);
                setStudent(studentData);
            } catch (error) {
                console.log(error);
            }
        })();

    }, [selectedStudent])

    useEffect(() => {
        const newSelectablePeriods = [];

        // get the last period for the pupil
        if (student.pupil_periods) {
            let lastPeriod = student.pupil_periods[student.pupil_periods.length - 1];

            student.pupil_periods.forEach((period) => {
                if (period.assessment) {
                    newSelectablePeriods.push(period);
                }
            });

            if (student.baseline_assessment) {
                newSelectablePeriods.push({
                    period_name: 'Baseline',
                    assessment: student.baseline_assessment,
                    calendar_year: student.pupil_periods[0].calendar_year
                });
            }

            console.log('new selectable periods', newSelectablePeriods);

            setSelectablePeriods(newSelectablePeriods);
            setCurrentPeriod(lastPeriod);
        }

    }, [student])

    useEffect(() => {
        // set the selected period to the current period
        if (currentPeriod) {
            console.log('current period settting', currentPeriod);
            setSelectedPeriods([]);
            updateSelectedPeriods(currentPeriod);
        }
    }, [currentPeriod])


    const updateSelectedPeriods = (period) => {
        let newSelectedPeriods = [...selectedPeriods];

        if (newSelectedPeriods.includes(period)) {
            newSelectedPeriods = newSelectedPeriods.filter((selectedPeriod) => selectedPeriod !== period);
        } else {
            newSelectedPeriods.push(period);
        }

        let orderedSelectedPeriods = newSelectedPeriods.sort((a, b) => {
            if (a.period_name === 'Baseline') {
                return -1;
            }

            return a.id - b.id;
        });

        setSelectedPeriods(orderedSelectedPeriods);
    }

    // report config is ready
    useEffect(() => {

        if (!student || !selectablePeriods || selectablePeriods.length === 0 ||  !selectedPeriods || selectedPeriods.length === 0) {
            return;
        }

        // reset pdf
        setPdfReady(false);

        // outcome statements
        const mostRecentSelectedPeriod = selectedPeriods[selectedPeriods.length - 1];
        const previousPeriod = calcPreviousPeriod(student.pupil_periods, mostRecentSelectedPeriod);
        const bundles = calcBundlesWithIntervention(mostRecentSelectedPeriod, previousPeriod);
        setBundllesWithIntervention(bundles);

    }, [selectedPeriods, student])


    return (
        <div className="update-student-progress">
            <h1>View Student Progress</h1>

            <SelectStudent setSelectedStudent={setSelectedStudent} selectedStudent={selectedStudent} />

            <div className="full-width-select-cont">
                <CustomMultiSelectPeriod
                    name="period-select" 
                    placeholder="Select reporting periods"
                    options={selectablePeriods ? selectablePeriods : []}
                    setSelectedOption={(period) => updateSelectedPeriods(period)}
                    selectedOptions={selectedPeriods}
                />
            </div>

            <div class="separator"></div>

            {student && currentPeriod && selectedPeriods && selectedPeriods.length > 0 && selectablePeriods && selectablePeriods.length > 0 &&
                <>
                    <div>

                        <div className="student-details-cont">
                            <h3>{student.name} {currentPeriod && ` - Year ${currentPeriod.year_group}`} {student.school && student.school.name && ` - ${student.school.name}`}</h3>
                        </div>

                        <ul className="report-details-list">
                            <li><b>School</b> - {student.school && student.school.name}</li>
                            <li><b>Current Intervention lead</b> - 
                            {currentPeriod && ` ${currentPeriod.tutor_group && currentPeriod.tutor_group.tutor && currentPeriod.tutor_group.tutor.name}`}
                            </li>
                            <li><b>Reporting Periods</b> - 
                                {selectedPeriods && selectedPeriods[0] && selectedPeriods.map((selectedPeriod, index) => 
                                    <>
                                    <span>{selectedPeriod.period_name} {selectedPeriod.calendar_year} {index !== selectedPeriods.length - 1 && ', '}</span>
                                    </>
                                )}
                            </li>
                        </ul>
                        
                        <div className="progress-outer-cont" ref={progressTableRef}>
                            <ProgressTable periods={student.pupil_periods} selectedPeriods={selectedPeriods} />
                        </div>

                        <div className="progress-chart-outer-cont" ref={progressBarChartRef}>
                            <ProgressBarChart selectedPeriods={selectedPeriods} />
                        </div>

                        <div className="progress-outcome-statements">
                            <ProgressOutcomeStatements bundlessWithIntervention={bundlessWithIntervention} currentPeriod={selectedPeriods[selectedPeriods.length - 1]} />
                        </div>
                    </div>

                    <StudentProgressPDF
                        pdfReady={pdfReady}
                        setPdfReady={(ready) => setPdfReady(ready)}
                        student={student}
                        currentPeriod={currentPeriod}
                        selectedPeriods={selectedPeriods}
                        bundlessWithIntervention={bundlessWithIntervention}
                        progressTableRef={progressTableRef}
                        progressBarChartRef={progressBarChartRef}
                    />
                </>
            }
        </div>
    );
}

export default StudentProgress;