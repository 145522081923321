import React from "react";
import LoginLayout from "../../layouts/LoginLayout";
import PasswordResetRequestForm from "../../features/passwordReset/PasswordResetRequestForm";

const PasswordResetRequestPage = () => {
    return (
        <LoginLayout>
            
            <h1>The School Consultancy Programme</h1>
            <h2>Student tracking pupil</h2>

            <div className="separator"></div>

            <PasswordResetRequestForm />

        </LoginLayout>
    );
}

export default PasswordResetRequestPage;