import React, { useState, useEffect } from "react";
import CrudUserList from '../../features/crudUser/CrudUserList';
import CrudUserSingle from '../../features/crudUser/CrudUserSingle';
import { getUser } from '../../services/scpApi';

const ManageUsers = () => {

    const [view, setView] = useState("list");
    const [selectedSingleItemId, setSelectedSingleItemId] = useState(null);
    const [selectedUser, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleAddNew = () => {
        setView("single");
        setSelectedSingleItemId(null);
        setUser(null);
    };

    const handleViewChange = ({isBackToList = false}) => {
        console.log(isBackToList);
        if (isBackToList) {
            setView("list");
            return;
        }

        setView(view === "list" ? "single" : "list");
    };

    const refreshSelectedUser = () => {
        if (!selectedSingleItemId) {
            return;
        }
        (async () => {
            try {
                const data = await getUser({
                    userId: selectedSingleItemId
                });
                setUser(data.user);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        })();
    }

    useEffect(() => {
        (async () => {
            if (!selectedSingleItemId) {
                return;
            }
            try {
                // fetch student data
                const data = await getUser({
                    userId: selectedSingleItemId
                });
                setUser(data.user);

            } catch (error) {
                console.error('Error fetching user:', error);
            }
        })();
    }, [selectedSingleItemId]);

    return (
        <div className="crud-page">
            <h1>Manage Users</h1>

            {view === "list" ? (
                <>
                    <button onClick={handleAddNew} className="add-new">Add New User</button>
                    <CrudUserList setSelectedSingleItemId={setSelectedSingleItemId} setView={handleViewChange} />
                </>
            ) : (
                <CrudUserSingle loading={loading} setLoading={setLoading} user={selectedUser} setView={handleViewChange} refreshSelectedUser={refreshSelectedUser} setUser={setUser} />
            )}
        </div>
    );
};

export default ManageUsers;